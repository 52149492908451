<template>
  <div class="popup_video" :class="{'on': videoStatus}">
    <div class="cont">
      <video :src="videoSrc" id="popup_video_vid" preload="auto" x5-video-player-type="h5" x5-video-player-fullscreen="true" x-webkit-airplay="allow" webkit-playsinline playsinline controls controlsList="nodownload" loop></video>
      <div class="sole iconfont" @click="closeVideo">&#xe60f;</div>
    </div>
  </div>
</template>

<script>
import { useMyStore } from '../../store';
export default {
  computed: {
    videoStatus() {
      return useMyStore().VideoPopStatus;
    },
    videoSrc() {
      return useMyStore().videoScr;
    }
  },
  methods: {
    closeVideo() {
      useMyStore().setPopHide();
    }
  }
}
</script>

<style>

</style>