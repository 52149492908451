import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import VueAwesomeSwiper  from "vue-awesome-swiper";
import axios from 'axios'
import Share from 'vue-social-share'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import $ from 'jquery'
import VueAnimateOnScroll from 'vue-animate-onscroll'
import AMapLoader from "@amap/amap-jsapi-loader";
import { createPinia } from 'pinia'


//全局公共样式
import "./assets/css/animate.min.css";
import "swiper/dist/css/swiper.css";
import 'vue-social-share/dist/client.css';
import './assets/css/global.css'
import './assets/css/style.css'
import './assets/css/responsive.css'

const pinia = createPinia();
Vue.use(pinia)

Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.use(Share)
Vue.use(VueAnimateOnScroll)

AMapLoader.load({
  'key': 'd05f872844b80ea5decf4b101b11971c',           
  'version': '2.0',
  'plugins': ['AMap.Scale','AMap.GeoJSON']    
}).then((AMap) => {
  Vue.use(AMap)
})

//配置请求的根路径
axios.defaults.baseURL = 'https://gwadmin.anjilog.com/api/'
// axios.defaults.baseURL = 'http://ajwl2024.jwkj.site/api/'

// 设置请求拦截器
axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    axios.defaults.headers.common['token'] = token;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// 添加响应拦截器
let isRefreshing = false
axios.interceptors.response.use(
  response  => {
      const res = response.data;
      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common['token'] = token;
      }
      // console.log(token)
      if (res.status == 401) {
        if (!isRefreshing) {
          isRefreshing = true
          axios.post('refreshToken').then(response => {
            if(response.data.info == "获取成功" && response.data.status == 1) {
              const newtoken = response.data.data.token;
              localStorage.setItem('token', newtoken); 
              axios.defaults.headers.common['token'] = newtoken;
              console.log(newtoken)
              return response.config
            }
            // else{
            //   router.push('/login')
            //   localStorage.clear()
            // }
          }).catch(err => {
          //跳到登录页
           // router.push('/login')
          //   return Promise.reject(err)
          })
        }
      }
      return response
  },
  error => {
    //if (res.info == "Token已失效") {
      // axios.post('refreshToken')
      // .then(response => {
      //   console.log(response.data.data.token)
      //   // const newToken = response.data.data.token;
      //   // localStorage.setItem('token', newToken);
      //   // // 重发之前失败的请求
      //   // axios.defaults.headers.common['token'] = token;
      //   //return axios(error.config);
      // })
      // .catch(refreshError => {
      //   // localStorage.clear()
      //   // router.replace('/login')
      // });
    //}
      // Message({
      //     message: error.message,
      //     type: 'error',
      //     duration: 5 * 1000
      // })
      return Promise.reject(error)
  }
)

export default axios



Vue.prototype.$http = axios

NProgress.configure({ showSpinner: false })
router.beforeEach((to, from, next) => {
    NProgress.start();
    next()
    
});
 
router.afterEach(transition => {
	NProgress.done();
  
});

new Vue({
  router,
  $,
  pinia,
  render: h => h(App)
}).$mount('#app')

