import Vue from 'vue'
import { Button,Message, Pagination,Collapse,CollapseItem,Form,FormItem,Input,Upload,DatePicker,Select,Option} from 'element-ui'

Vue.use(Button)
Vue.use(Pagination)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Upload)
Vue.use(DatePicker)
Vue.use(Select)
Vue.use(Option)

Vue.prototype.$message = Message

