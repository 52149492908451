import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
const Home = () => import(/* webpackChunkName: "group-home" */ '../views/index.vue')

// import About from '../views/About.vue'
const About = () => import(/* webpackChunkName: "group-about" */ '../views/About.vue')

const zbcg = () => import(/* webpackChunkName: "group-about" */ '../components/about/zbcg.vue')

const zbcgDetail = () => import(/* webpackChunkName: "group-about" */ '../components/about/zbcgDetail.vue')

// import Business from '../views/Business.vue'
const Business = () => import(/* webpackChunkName: "group-business" */ '../views/Business.vue')

const businessView = () => import(/* webpackChunkName: "group-businessView" */ '../views/businessView.vue')

//import companyNews from '../views/News.vue'
const companyNews = () => import(/* webpackChunkName: "group-news" */ '../views/News.vue')

// import PartyNews from '../views/Party.vue'
const PartyNews = () => import(/* webpackChunkName: "group-news" */ '../views/Party.vue')

// import RecuitM from '../views/Recuit.vue'
const RecuitM = () => import(/* webpackChunkName: "group-recuit" */ '../views/Recuit.vue')

// import ContactUs from '../views/ContactUs.vue'
const ContactUs = () => import(/* webpackChunkName: "group-contact" */ '../views/ContactUs.vue')

// import Introduct from '../components/about/Introduct.vue'
const Introduct = () => import(/* webpackChunkName: "group-about" */ '../components/about/Introduct.vue')

// import culture from '../components/about/Culture.vue'
const culture = () => import(/* webpackChunkName: "group-about" */ '../components/about/Culture.vue')

const Organize = () => import(/* webpackChunkName: "group-about" */ '../components/about/Organize.vue')

const djlh = () => import(/* webpackChunkName: "group-about" */ '../components/about/Party.vue')

const PartyList = () => import(/* webpackChunkName: "group-about" */ '../components/about/PartyList.vue')

const djlhDetail = () => import(/* webpackChunkName: "group-about" */ '../components/about/PartyDetail.vue')

const qtjl = () => import(/* webpackChunkName: "group-about" */ '../components/about/qtjl.vue')

const qtjlDetail = () => import(/* webpackChunkName: "group-about" */ '../components/about/qtjlDetail.vue')

// import subsidiary from '../components/about/subsidiary.vue'
const subsidiary = () => import(/* webpackChunkName: "group-about" */ '../components/about/subsidiary.vue')

// import honor from '../components/about/Honor.vue'
const honor = () => import(/* webpackChunkName: "group-about" */ '../components/about/Honor.vue')

// import businessDetail from '../components/business/businessDetail.vue'
const businessDetail = () => import(/* webpackChunkName: "group-business" */ '../components/business/businessDetail.vue')

// import News from '../components/news/CompanyNew.vue'
const News = () => import(/* webpackChunkName: "group-news" */ '../components/news/CompanyNew.vue')

const zgNews = () => import(/* webpackChunkName: "group-news" */ '../components/news/zigongsixinwen.vue')

// import IndustryNews from '../components/news/IndustryNews.vue'
const IndustryNews = () => import(/* webpackChunkName: "group-news" */ '../components/news/IndustryNews.vue')

const CompanyVideo = () => import(/* webpackChunkName: "group-news" */ '../components/news/CompanyVideo.vue')

// import newsDetail from '../components/news/NewsDetail.vue'
const newsDetail = () => import(/* webpackChunkName: "group-news" */ '../components/news/NewsDetail.vue')

// import Concept from '../components/recruit/concept.vue'
const Concept = () => import(/* webpackChunkName: "group-recuit" */ '../views/Idea.vue')

// import Recruit from '../components/recruit/recruit.vue'
const Recruit = () => import(/* webpackChunkName: "group-recuit" */ '../components/recruit/recruit.vue')

const campusrecruit = () => import(/* webpackChunkName: "group-campusrecruit" */ '../components/recruit/campusrecruit.vue')
const lygs = () => import(/* webpackChunkName: "group-recuit" */ '../components/recruit/lygs.vue')
const lygsDetail = () => import(/* webpackChunkName: "group-news" */ '../components/recruit/lygsDetail.vue')

// import Style from '../components/recruit/style.vue'
const Style = () => import(/* webpackChunkName: "group-recuit" */ '../components/recruit/style.vue')

// import Contact from '../components/contact/contact.vue'
const Contact = () => import(/* webpackChunkName: "group-contact" */ '../components/contact/contact.vue')

// import Message from '../components/contact/message.vue'
const Message = () => import(/* webpackChunkName: "group-contact" */ '../components/contact/message.vue')

const Login = () => import(/* webpackChunkName: "group-login" */ '../views/Login.vue')

const Register = () => import(/* webpackChunkName: "group-register" */ '../views/Register.vue')

const Forget = () => import(/* webpackChunkName: "group-forget" */ '../views/Forget.vue')

const Personal = () => import(/* webpackChunkName: "group-Personal" */ '../views/Personal.vue')

const Biographical = () => import(/* webpackChunkName: "group-Biographical" */ '../views/Biographical.vue')

const Searchs = () => import(/* webpackChunkName: "group-search" */ '../views/Search.vue')

const Edit = () => import(/* webpackChunkName: "group-Biographical" */ '../views/Edit.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: 0
    }
  },
  {
    path: '/about',
    component: About,
    redirect:'/about/about',
    children: [
      {
        path: '/about/about',
        component: Introduct,
        
      },
      {
        path: '/about/culture',
        component: culture
      },
      {
        path: '/about/partybuilding',
        component: djlh
      },
      {
        path: '/about/partylist/:cid',
        component: PartyList
      },
      {
        path: '/party/:cid',
        component: djlhDetail
      },
      {
        path: '/about/partyact',
        component: qtjl
      },
      {
        path: '/partyact/:cid',
        component: qtjlDetail
      },
      {
        path: '/about/subsidiary',
        component: subsidiary
      },
      {
        path: '/about/organize',
        component: Organize
      },
      {
        path: '/about/zhaobiaocaigou',
        component: zbcg
      },
      {
        path: '/zbcgDetail/:cid',
        name: 'zbcgDetail',
        component: zbcgDetail,
      },
      {
        path: '/about/honor',
        component: honor
      }
    ]
  },
  {
    path: '/business',
    name: 'business',
    component: Business,
  },
  {
    path: '/businessView/:catid',
    name: 'businessView',
    component: businessView,
  },
  {
    path: '/business/businessDetail/:cid',
    name: 'businessDetail',
    component: businessDetail,
  },
  {
    path: '/companynews',
    component: companyNews,
    redirect:'/companynews/companynews',
    children:[
      {
        path: '/companynews/companynews',
        component: News, 
      },
      {
        path: '/companynews/zigongsixinwen',
        component: zgNews
      },
      {
        path: '/companynews/industrynews',
        component: IndustryNews
      },
      {
        path: '/companynews/video',
        component: CompanyVideo
      },
      {
        path: '/companynews/:cid',
        name: 'newsDetail',
        component: newsDetail,
      },
    ]
  },
  {
    path: '/concept',
    component: RecuitM,
    redirect:'/concept/concept',
    children:[
      {
        path: '/concept/concept/',
        component: Concept,
      },{
        path: '/concept/socialrecruit',
        component: Recruit,
      },{
        path: '/concept/style',
        component: Style,
      },{
        path: '/concept/campusrecruit',
        component: campusrecruit,
      },{
        path: '/concept/lygs',
        component: lygs,
      },
      {
        path: '/lygsDetail/:cid',
        name: 'lygsDetail',
        component: lygsDetail,
      }
    ]
  },
  {
    path: '/contact',
    component: ContactUs,
    redirect:'/contact/contact',
    children:[
      {
        path: '/contact/contact',
        component: Contact,
      },{
        path: '/contact/message',
        component: Message,
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/forget',
    name: 'forget',
    component: Forget,
  },
  {
    path: '/personal',
    name: 'personal',
    component: Personal,
  },
  {
    path: '/biographical/:id',
    name: 'biographical',
    component: Biographical,
  },
  {
    path: '/search',
    name: 'searchs',
    component: Searchs,
  },
  {
    path: '/edit/:id',
    name: 'edit',
    component: Edit,
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.afterEach(() => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

export default router
