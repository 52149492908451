import { defineStore } from 'pinia';

export const useMyStore = defineStore({
  id: 'myStore',
  state: () => ({
    count: 0,
    VideoPopStatus: false,
    videoScr: "",
    token: null,
    RecuitePop: false,
    keywords: ""
  }),
  actions: {
    increment() {
      this.count++;
    },
    decrement() {
      this.count--;
    },
    setPopShow(e) {
      this.VideoPopStatus = true
      this.videoScr = e
      setTimeout(()=>{
        var myVideo=document.getElementById("popup_video_vid");
        myVideo.play();
      }, 200)
    },
    setPopHide() {
      this.VideoPopStatus = false
      setTimeout(()=>{
        var myVideo=document.getElementById("popup_video_vid");
        myVideo.pause();
      }, 200)
    },
    setToken(token) {
      this.token = token;
    },
    hideToken(){
      this.token = ''
    },
    setPoprecuiteShow() {
      this.RecuitePop = true
    },
    setPoprecuiteHide() {
      this.RecuitePop = false
    },
    setKeyword(val) {
      this.keywords = val
    }
  }
})